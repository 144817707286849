"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

// Form Hubspot (Guida Mansarda)
// NB per istanziare la stessa form piu' volte devo ripetere i blocchi, perche' il target puo' essere solo un elemento singolo
if (typeof hbspt !== "undefined") {
  hbspt.forms.create({
    target: "#newsletter_form",
    css: "",
    portalId: "427615",
    formId: "2334882c-074b-4a54-a762-3dc5f3510721",
    onFormSubmit: function onFormSubmit($form) {
      // Tracking con Google Tag Manager
      if ((typeof dataLayer === "undefined" ? "undefined" : _typeof(dataLayer)) === "object") {
        dataLayer.push({
          event: "iscrizione-guida-mansarda"
        });
      } else {
        console.log("dataLayer.push({'event': 'iscrizione-guida-mansarda'});");
      }
    }
  });
  hbspt.forms.create({
    target: "#newsletter_form_footer",
    css: "",
    portalId: "427615",
    formId: "2334882c-074b-4a54-a762-3dc5f3510721",
    onFormSubmit: function onFormSubmit($form) {
      // Tracking con Google Tag Manager
      if ((typeof dataLayer === "undefined" ? "undefined" : _typeof(dataLayer)) === "object") {
        dataLayer.push({
          event: "iscrizione-guida-mansarda"
        });
      } else {
        console.log("dataLayer.push({'event': 'iscrizione-guida-mansarda'});");
      }
    }
  });
}
// Form Hubspot (Guida Mansarda)
// NB per istanziare la stessa form piu' volte devo ripetere i blocchi, perche' il target puo' essere solo un elemento singolo
if (typeof hbspt !== "undefined") {
	hbspt.forms.create({
		target: "#newsletter_form",
		css: "",
		portalId: "427615",
		formId: "2334882c-074b-4a54-a762-3dc5f3510721",
		onFormSubmit: function ($form) {
			// Tracking con Google Tag Manager
			if (typeof dataLayer === "object") {
				dataLayer.push({ event: "iscrizione-guida-mansarda" });
			} else {
				console.log("dataLayer.push({'event': 'iscrizione-guida-mansarda'});");
			}
		},
	});
	hbspt.forms.create({
		target: "#newsletter_form_footer",
		css: "",
		portalId: "427615",
		formId: "2334882c-074b-4a54-a762-3dc5f3510721",
		onFormSubmit: function ($form) {
			// Tracking con Google Tag Manager
			if (typeof dataLayer === "object") {
				dataLayer.push({ event: "iscrizione-guida-mansarda" });
			} else {
				console.log("dataLayer.push({'event': 'iscrizione-guida-mansarda'});");
			}
		},
	});
}
